<template>
    <div class="grid">
        <div class="field col-12">
            <label for="nome">Nome</label>
            <InputText
                id="nome"
                type="text"
                v-model="nome"
                :class="(fieldsError.some(v => v === 'nome'))?'w-full p-invalid':'w-full'"
                maxlength="50"
            />
        </div>
        <div class="field col-12">
            <label for="descricao">Valor</label>
            <InputNumber
            id="valor"
            mode="currency"
            currency="BRL"
            locale="pt-BR"
            v-model="valor"
            :class="fieldsError.some((v) => v === 'valor')?'p-invalid':''"
            />
        </div>        
        <div class="field col-6 mb-0">
            <label for="publicado">Publicado</label>
            <InputSwitch 
                id="publicado" 
                v-model="publicado" 
                :class="(fieldsError.some(v => v === 'publicado'))?'p-invalid':''"
            />
        </div>
        <div class="field col-6 mb-0 text-right">
            <label></label>
            <Button label="Gravar" class="p-button-success p-button-sm" icon="pi pi-check" @click="gravar"></Button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        personalizacao: Object,
        categoria: String
    },
    emits: [
        "save"
    ],
    data() {
        return {
            novo: true,

            // 1.0 Validação
            fieldsError: [],

            // 2.0 Campos Formulário
            nome: "",
            valor: 0,
            publicado: true,
        }
    },
    watch: {

        $props: {
            handler() {

                if(typeof this.personalizacao == "object" && this.personalizacao != null) {
                    this.novo = false;

                    this.nome = this.personalizacao.nome;
                    this.publicado = this.personalizacao.publicado;
                    this.valor = this.personalizacao.valor;
                } else {
                    this.novo = true;

                    this.nome = "";
                    this.publicado = true;
                    this.valor = 0;

                    this.fieldsError = [];
                }

            },
            deep: true,
            immediate: true,
        },

    },
    methods: {

        validateForm: function() {

            this.fieldsError = [];

            if (this.nome == "") {
                this.fieldsError.push("nome");
            }

            if (this.fieldsError.length) {
                this.$toast.add({
                    severity: "error",
                    summary: "ERRO!",
                    detail: "Preencha corretamente todas as informações!",
                    life: 5000,
                });

                return false;
            }

            return true;
        },

        gravar: function() {
            if(this.validateForm()) {
                
                let personalizacao;

                if(this.novo) {
                    personalizacao = {
                        categoria: this.categoria,
                    };
                } else {
                    personalizacao = this.$utils.getStdObject(this.personalizacao);
                }

                personalizacao.nome = this.nome;
                personalizacao.publicado = this.publicado;
                personalizacao.valor = this.valor;

                this.$emit("save", {value: personalizacao});

            }
        },

    },
}
</script>