<template>
  <BlockUI :blocked="loading > 0">

    <header class="flex mb-4">
      <h1 class="text-3xl font-semibold"><i class="pi pi-ticket text-2xl"></i> Produtos</h1>
    </header>

    <Button label="Adicionar Categoria" class="p-button-sm mb-4" icon="pi pi-plus" @click="cadastro_categoria($event, null)"></Button>
    <Button label="Adicionar Produto Automatizado" class="p-button-sm mb-4 ml-3" icon="pi pi-plus" @click="produto_automatizado_edit_visible = true"></Button>

    <section class="flex" v-if="Object.values(categorias).length">

      <Accordion :multiple="true" class="w-full">
        <AccordionTab v-for="cat of categorias" :key="cat.id">
          <template #header>
            <i class="pi pi-tags"></i>
            <span class="ml-2">{{ cat.nome }}</span>
            <div style="margin-left: auto; order: 2;">

              <ToggleButton v-model="cat.publicado" style="vertical-align: middle; margin-right: 2em"
                v-tooltip.top="cat.publicado ? 'Clique para Ocultar' : 'Clique para Mostrar'" onIcon="pi pi-eye"
                offIcon="pi pi-eye-slash" @click="alterar_publicacao_categoria($event, cat)" />

              <Button icon="pi pi-pencil" v-tooltip.top="'Alterar Categoria'" class="p-button-warning p-button-rounded"
                @click="cadastro_categoria($event, cat)" />
              <Button icon="pi pi-trash" v-tooltip.top="'Excluir Categoria'" class="ml-2 p-button-danger p-button-rounded"
                @click="excluir_categoria($event, cat)" />
            </div>
          </template>

          <Button label="Adicionar Produto" class="p-button-sm mb-4" icon="pi pi-plus"
            @click="cadastro_produto($event, cat)"></Button>

          <Accordion :multiple="true" class="w-full">
            <AccordionTab v-for="prod of cat.produtos" :key="prod.id">
              <template #header>
                <i class="pi pi-ticket"></i>
                <span class="ml-2">{{ prod.nome }}</span>
                <div style="margin-left: auto; order: 2;">

                  <a :href="url + '/produto/' + prod.id" target="_blank" style="vertical-align: middle; margin-right: 1em"
                    class="p-button p-button-secondary"><i class="pi pi-external-link"></i></a>
                  <ToggleButton v-model="prod.publicado" style="vertical-align: middle; margin-right: 1em"
                    v-tooltip.top="prod.publicado ? 'Clique para Ocultar' : 'Clique para Mostrar'" onIcon="pi pi-eye"
                    offIcon="pi pi-eye-slash" @click="alterar_publicacao_produto($event, prod)" />
                  <ToggleButton v-model="prod.favoritoWeb" style="vertical-align: middle; margin-right: 2em"
                    v-tooltip.top="prod.favoritoWeb ? 'Clique p/ remover Destaque' : 'Clique p/ Destacar'"
                    onIcon="pi pi-star-fill" offIcon="pi pi-star" @click="alterar_destaque_produto($event, prod)" />

                  <Button icon="pi pi-pencil" v-tooltip.top="'Alterar Produto'" class="p-button-warning p-button-rounded"
                    @click="cadastro_produto($event, cat, prod)" />
                  <Button icon="pi pi-trash" v-tooltip.top="'Excluir Produto'"
                    class="ml-2 p-button-danger p-button-rounded" @click="excluir_produto($event, prod)" />
                </div>
              </template>

              <div class="w-full"
                v-if="typeof prod.personalizacoes != 'object' || Object.values(prod.personalizacoes).length == 0">
                Nenhuma personalização cadastrada para esse produto.
              </div>

              <Accordion :multiple="true" class="w-full">
                <AccordionTab v-for="per of prod.personalizacoes" :key="per.id">
                  <template #header>
                    <i class="pi pi-sliders-v"></i>
                    <span class="ml-2">{{ per.nome }}</span>

                    <div class="text-right" style="margin-left: auto; order: 2;">
                      Mínimo: {{ per.limiteMinimo }}, Máximo: {{ per.limiteMaximo }}
                    </div>
                  </template>

                  <DataTable :value="Object.values(per.itens)" :lazy="true">

                    <Column field="nome" header="Nome" class="col-item-nome"></Column>
                    <Column field="valor" header="Valor" class="col-item-valor">
                      <template #body="slotProps">
                        {{ $utils.formatCurrency(slotProps.data.valor) }}
                      </template>
                    </Column>
                    <Column field="publicado" header="Publicado" class="col-item-publicado">
                      <template #body="slotProps">
                        <ToggleButton v-model="slotProps.data.publicado"
                          v-tooltip.top="slotProps.data.publicado ? 'Clique para Ocultar' : 'Clique para Mostrar'"
                          onIcon="pi pi-eye" offIcon="pi pi-eye-slash"
                          @click="alterar_publicacao_personalizacao($event, slotProps.data)" />
                      </template>
                    </Column>
                    <template #empty>
                      Nenhum Item cadatrado à essa Categoria de Personalização.
                    </template>
                  </DataTable>

                </AccordionTab>
              </Accordion>

            </AccordionTab>
          </Accordion>

        </AccordionTab>
      </Accordion>

    </section>

    <Dialog v-model:visible="categoria_edit_visible" style="width: 400px" :modal="true">
      <template #header>
        <div>
          <i class="pi pi-tags text-2xl"></i> <span class="text-2xl font-semibold">Cadastro de Categoria</span>
        </div>
      </template>
      <CategoriaEdit :categoria="categoria_edit" @save="categoria_gravada" />
    </Dialog>

    <Dialog v-model:visible="produto_edit_visible" style="width: 820px" :modal="true">
      <template #header>
        <div>
          <i class="pi pi-ticket text-2xl"></i> <span class="text-2xl font-semibold">Cadastro de Produto</span>
        </div>
      </template>
      <ProdutoEdit :categoria="produto_edit_categoria" :categoriaNome="produto_edit_categoria_nome"
        :produto="produto_edit" @save="produto_gravado" />
    </Dialog>

    <Dialog v-model:visible="produto_automatizado_edit_visible" style="width: 820px" :modal="true">
      <template #header>
        <div>
          <i class="pi pi-ticket text-2xl"></i> <span class="text-2xl font-semibold">Cadastro Automatizado de Produto</span>
        </div>
      </template>
      <ProdutoAutomatizadoEdit :produto="produto_edit" :categorias="categorias_sem_produtos" @save="produto_gravado" />
    </Dialog>

  </BlockUI>

  <ConfirmPopup></ConfirmPopup>
  <Toast position="top-right" />
</template>

<script>

import CategoriaEdit from "./CategoriaEdit.vue";
import ProdutoEdit from "./ProdutoEdit.vue";
import ProdutoAutomatizadoEdit from "./ProdutoAutomatizadoEdit.vue";

import firebase from 'firebase/app';
import 'firebase/database';

export default {

  data() {

    return {

      loading: 0,

      categoria_edit_visible: false,
      categoria_edit: null,

      produto_automatizado_edit_visible: false,
      produto_edit_visible: false,
      produto_edit_categoria: "",
      produto_edit_categoria_nome: "",
      produto_edit: null,

      categorias: {},
      categorias_sem_produtos: [],
      url: "",

    };

  },

  mounted() {

    this.loading++;

    const self = this;

    let timeUser = setInterval(() => {
      if (typeof self.$root.user == "object" && self.$root.user != null) {

        self.url = "https://ecommerce-vip-delivery.web.app/" + self.$root.user.id;
        firebase.database().ref('Usuarios').child(self.$root.user.id).child("dominio").once("value", (snapshot) => {
          if (snapshot.exists())
            self.url = "https://" + snapshot.val();
        });

        self.loadData();
        clearInterval(timeUser);
        self.loading--;
      }
    }, 500);

  },

  methods: {

    loadData: function () {

      this.loading++;

      const self = this;

      const ref = firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id);

      ref.once('value', function (snapshot) {

        if (snapshot.exists()) {
          self.categorias = snapshot.val();

          self.categorias_sem_produtos = [];
          for(const c of Object.values(self.categorias)) {
            const categoria = JSON.parse(JSON.stringify(c));
            if(typeof categoria.produtos != "undefined")
              delete categoria.produtos;
            self.categorias_sem_produtos.push(categoria);
          }

        }

        self.loading--;

      });

    },

    cadastro_categoria: function (event, categoria) {
      event.stopPropagation();

      this.categoria_edit = categoria;
      this.categoria_edit_visible = true;

    },

    categoria_gravada: async function(event) {

      const self = this;

      this.categoria_edit_visible = false;
      this.categoria_edit = null;

      const ref = firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id);

      if (typeof event.value.id == "undefined") {

        event.value.id = ref.push().key;

      }

      const snapshot = await ref.child(event.value.id).once("value");
      if(snapshot.exists()) {
        event.value.produtos = snapshot.val().produtos;
      }

      if(typeof event.value.produtos != "object")
        event.value.produtos = {};

      ref.child(event.value.id).set(event.value, function(err) {

        if (!err) {

          self.db_atualizar_produtos_publicados_categoria(event.value.id, function (err) {

            if (err) {

              console.log("Erro ao atualizar categoria dos produtos publicados");
              console.log(err);

              self.$toast.add({
                severity: "error",
                summary: "ERRO!",
                detail: "Erro ao gravar a categoria! Verifique o LOG.",
                life: 5000,
              });

            } else {

              self.categorias[event.value.id] = event.value;

              self.$toast.add({
                severity: "success",
                summary: "Sucesso!",
                detail: "Categoria gravada com sucesso!",
                life: 3000,
              });

            }

          });

        } else {

          console.log(err);

          self.$toast.add({
            severity: "error",
            summary: "ERRO!",
            detail: "Erro ao gravar a categoria! Verifique o LOG.",
            life: 5000,
          });

        }

      });

    },

    excluir_categoria: function (event, categoria) {
      event.stopPropagation();

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir essa categoria e seus produtos? ATENÇÃO: Essa operação não poderá ser desfeita!',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

          firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id)
            .child(categoria.id).remove(function (err) {

              if (!err) {

                self.db_atualizar_produtos_publicados_categoria(categoria.id, function (err) {

                  if (err) {

                    console.log("Erro ao excluir produtos publicados");
                    console.log(err);

                    self.$toast.add({
                      severity: "error",
                      summary: "ERRO!",
                      detail: "Erro ao excluir a categoria! Verifique o LOG.",
                      life: 5000,
                    });

                  } else {

                    delete self.categorias[categoria.id];

                    self.$toast.add({
                      severity: "success",
                      summary: "Sucesso!",
                      detail: "Categoria excluída com sucesso!",
                      life: 3000,
                    });

                  }

                });

              } else {

                console.log(err);

                self.$toast.add({
                  severity: "error",
                  summary: "ERRO!",
                  detail: "Erro ao excluir a categoria! Verifique o LOG.",
                  life: 5000,
                });

              }

            });

        }
      });

    },

    cadastro_produto: function (event, categoria, produto = null) {
      event.stopPropagation();

      this.produto_edit_categoria = categoria.id;
      this.produto_edit_categoria_nome = categoria.nome;
      this.produto_edit = produto;

      this.produto_edit_visible = true;

    },

    produto_gravado: function (event) {

      const self = this;

      this.produto_edit_visible = false;
      this.produto_edit = null;

      if(typeof event.value.cadastrarCategoria == "boolean" && event.value.cadastrarCategoria) {

        this.categoria_gravada({
          "value": {
            "id": event.value.categoria,
            "nome": event.value.categoriaNome,
            "publicado": true,
          }
        });

        delete event.value.cadastrarCategoria;

      }

      const ref = firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id)
        .child(event.value.categoria).child('produtos');

      if (typeof event.value.id == "undefined") {

        event.value.id = ref.push().key;

        for (const k in event.value.personalizacoes) {
          event.value.personalizacoes[k].produto = event.value.id;
          event.value.personalizacoes[k].produtoCategoria = event.value.categoria;
          for (const kk in event.value.personalizacoes[k].itens) {
            event.value.personalizacoes[k].itens[kk].produto = event.value.id;
            event.value.personalizacoes[k].itens[kk].produtoCategoria = event.value.categoria;
          }
        }

      }

      if (typeof event.value.publicado == "undefined") {
        event.value.publicado = false;
      }

      if (typeof event.value.loja == "undefined") {
        event.value.loja = this.$root.user;
      }

      ref.child(event.value.id).set(event.value, function (err) {

        self.db_alterar_produto_publicado(event.value, function (errP) {

          if (err || errP) {

            console.log("Erro de Cadastro Principal:", err);
            console.log("Erro de Cadastro Publicados:", errP);

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: "Erro ao gravar o produto! Verifique o LOG.",
              life: 5000,
            });

          } else {

            window.location.reload(); // POG

            self.$toast.add({
              severity: "success",
              summary: "Sucesso!",
              detail: "Produto gravado com sucesso!",
              life: 3000,
            });

            // var interval_cad_cat = setInterval(() => {

            //   if(typeof self.categorias[event.value.categoria] != "undefined") {
                
            //     clearInterval(interval_cad_cat);

            //     if(typeof self.categorias[event.value.categoria].produtos == "undefined")
            //       self.categorias[event.value.categoria].produtos = {};

            //     self.categorias[event.value.categoria].produtos[event.value.id] = event.value;

            //     self.loadData();

            //     self.$toast.add({
            //       severity: "success",
            //       summary: "Sucesso!",
            //       detail: "Produto gravado com sucesso!",
            //       life: 3000,
            //     });

            //   }

            // },700);

          }

        });

      });

    },

    excluir_produto: function (event, produto) {
      event.stopPropagation();

      const self = this;

      this.$confirm.require({
        target: event.currentTarget,
        message: 'Tem certeza que deseja excluir esse produto?\nATENÇÃO: Essa operação não poderá ser desfeita!',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        accept: () => {

          firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id)
            .child(produto.categoria).child('produtos').child(produto.id).remove(function (err) {

              produto.publicado = false;

              self.db_alterar_produto_publicado(produto, function (errP) {

                if (err || errP) {

                  console.log("Erro de Cadastro Principal:", err);
                  console.log("Erro de Cadastro Publicados:", errP);

                  self.$toast.add({
                    severity: "error",
                    summary: "ERRO!",
                    detail: "Erro ao excluir o produto! Verifique o LOG.",
                    life: 5000,
                  });

                } else {

                  delete self.categorias[produto.categoria].produtos[produto.id];

                  self.$toast.add({
                    severity: "success",
                    summary: "Sucesso!",
                    detail: "Produto excluído com sucesso!",
                    life: 3000,
                  });

                }

              });

            });

        }

      });

    },

    // OK
    alterar_publicacao_categoria: async function (event, categoria) {
      event.stopPropagation();

      const self = this;

      let err = await firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id)
        .child(categoria.id).update({
          publicado: categoria.publicado
        });

      if (err) {

        console.log(err);

        self.$toast.add({
          severity: "error",
          summary: "ERRO!",
          detail: "Erro ao atualizar o status de publicação da categoria! Verifique o LOG.",
          life: 5000,
        });

        self.categorias[categoria.id].publicado = !categoria.publicado;

      } else {

        self.db_atualizar_produtos_publicados_categoria(categoria.id, function (err) {

          if (err) {

            console.log("Erro ao atualizar produtos publicados");
            console.log(err);

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: "Erro ao atualizar o status de publicação da categoria dos produtos publicados! Verifique o LOG.",
              life: 5000,
            });

          } else {

            self.$toast.add({
              severity: "success",
              summary: "Sucesso!",
              detail: "Status de publicação da categoria atualizado com sucesso!",
              life: 3000,
            });

          }

        });

      }

    },

    // OK
    alterar_publicacao_produto: function (event, produto) {
      event.stopPropagation();

      const self = this;

      firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id)
        .child(produto.categoria).child('produtos').child(produto.id).update({
          publicado: produto.publicado
        }, function (err) {

          self.db_alterar_produto_publicado(produto, function (errP) {

            if (err || errP) {

              console.log("Erro de Cadastro Principal:", err);
              console.log("Erro de Cadastro Publicados:", errP);

              self.$toast.add({
                severity: "error",
                summary: "ERRO!",
                detail: "Erro ao atualizar o status de publicação do produto! Verifique o LOG.",
                life: 5000,
              });

              self.categorias[produto.categoria].produtos[produto.id].publicado = !produto.publicado;

            } else {

              self.$toast.add({
                severity: "success",
                summary: "Sucesso!",
                detail: "Status de publicação do produto atualizado com sucesso!",
                life: 3000,
              });

            }

          });

        });

    },

    // OK
    alterar_publicacao_personalizacao: function (event, personalizacao) {
      event.stopPropagation();

      const self = this;

      firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id)
        .child(personalizacao.produtoCategoria).child('produtos').child(personalizacao.produto)
        .child('personalizacoes').child(personalizacao.categoria).child("itens").child(personalizacao.id).update({
          publicado: personalizacao.publicado
        }, function (err) {

          if (err) {

            console.log(err);

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: "Erro ao atualizar o status de publicação da personalização do produto! Verifique o LOG.",
              life: 5000,
            });

            self.categorias[personalizacao.produtoCategoria].produtos[personalizacao.produto]
              .personalizacoes[personalizacao.categoria].itens[personalizacao.id].publicado = !personalizacao.publicado;

          } else {

            const ref = firebase.database().ref('VipDelivery').child('ProdutosPublicados').child(personalizacao.produto);

            ref.once("value", (snapshot) => {

              if (snapshot.exists()) {

                ref.child('personalizacoes').child(personalizacao.categoria).child("itens").child(personalizacao.id).update({
                  publicado: personalizacao.publicado
                }, function (err) {

                  if (err) {

                    console.log(err);

                    self.$toast.add({
                      severity: "error",
                      summary: "ERRO!",
                      detail: "Erro ao atualizar o status de publicação da personalização do produto publicado! Verifique o LOG.",
                      life: 5000,
                    });

                    self.categorias[personalizacao.produtoCategoria].produtos[personalizacao.produto]
                      .personalizacoes[personalizacao.categoria].itens[personalizacao.id].publicado = !personalizacao.publicado;

                  } else {

                    self.$toast.add({
                      severity: "success",
                      summary: "Sucesso!",
                      detail: "Status de publicação da personalização do produto atualizado com sucesso!",
                      life: 3000,
                    });

                  }

                });

              } else {

                self.$toast.add({
                  severity: "success",
                  summary: "Sucesso!",
                  detail: "Status de publicação da personalização do produto atualizado com sucesso!",
                  life: 3000,
                });

              }

            });

          }

        });

    },

    // OK
    alterar_destaque_produto: function (event, produto) {
      event.stopPropagation();

      const self = this;

      firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id)
        .child(produto.categoria).child('produtos').child(produto.id).update({
          favoritoWeb: produto.favoritoWeb
        }, function (err) {

          if (err) {

            console.log(err);

            self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: "Erro ao atualizar o status de destaque do produto! Verifique o LOG.",
              life: 5000,
            });

            self.categorias[produto.categoria].produtos[produto.id].favoritoWeb = !produto.favoritoWeb;

          } else {

            const ref = firebase.database().ref('VipDelivery').child('ProdutosPublicados').child(produto.id);

            ref.once("value", (snapshot) => {

              if (snapshot.exists()) {

                ref.update({
                  favoritoWeb: produto.favoritoWeb
                }, function (err) {

                  if (err) {

                    console.log(err);

                    self.$toast.add({
                      severity: "error",
                      summary: "ERRO!",
                      detail: "Erro ao atualizar o status de destaque do produto publicado! Verifique o LOG.",
                      life: 5000,
                    });

                    self.categorias[produto.categoria].produtos[produto.id].favoritoWeb = !produto.favoritoWeb;

                  } else {

                    self.$toast.add({
                      severity: "success",
                      summary: "Sucesso!",
                      detail: "Status de destaque do produto atualizado com sucesso!",
                      life: 3000,
                    });

                  }

                });

              } else {

                self.$toast.add({
                  severity: "success",
                  summary: "Sucesso!",
                  detail: "Status de destaque do produto atualizado com sucesso!",
                  life: 3000,
                });

              }

            });

          }

        });

    },

    // OK
    db_alterar_produto_publicado: function (produto, callback) {

      if (typeof produto != "object" || produto == null) {
        if (typeof callback == "function")
          callback(true);
        return;
      }

      const ref = firebase.database().ref('VipDelivery').child('ProdutosPublicados').child(produto.id);

      ref.once("value", (snapshot) => {

        if (snapshot.exists()) {

          if (!produto.publicado) {

            ref.remove(function (err) {

              if (err) {

                if (typeof callback == "function")
                  callback(err);

              } else {

                if (typeof callback == "function")
                  callback(false);

              }

            });

          } else {

            ref.set(produto, function (err) {

              if (err) {

                if (typeof callback == "function")
                  callback(err);

              } else {

                if (typeof callback == "function")
                  callback(false);

              }

            });

          }

        } else {

          if (produto.publicado) {

            ref.set(produto, function (err) {

              if (err) {

                if (typeof callback == "function")
                  callback(err);

              } else {

                if (typeof callback == "function")
                  callback(false);

              }

            });

          } else {

            if (typeof callback == "function")
              callback(false);

          }

        }

      });

    },

    // OK
    db_atualizar_produtos_publicados_categoria: async function (categoria_id, callback) {

      let ref = firebase.database().ref('VipDelivery').child('ProdutosPublicados');

      let snapshot = await ref.orderByChild('categoria').equalTo(categoria_id).once('value');

      if (snapshot.exists()) {

        for (const p of Object.keys(snapshot.val())) {
          const err = await ref.child(p).remove();

          if (err) {

            if (typeof callback == "function") {
              callback(err);
              return;
            }

          }

        }

      }

      snapshot = await firebase.database().ref('VipDelivery').child('ProdutosCategorias').child(this.$root.user.id)
        .child(categoria_id).once("value");

      if (snapshot.exists()) {

        const categoria = snapshot.val();

        if (categoria.publicado) {

          if (typeof categoria.produtos != "undefined") {

            for (const p of Object.values(categoria.produtos)) {

              if (p.publicado) {

                const err = await ref.child(p.id).set(p);

                if (err) {

                  if (typeof callback == "function") {
                    callback(err);
                    return;
                  }

                }

              }

            }

          }

        }

      }

      callback(false);

    }

  },
  components: {
    CategoriaEdit,
    ProdutoEdit,
    ProdutoAutomatizadoEdit
  },

};
</script>

<style scoped>:deep(.col-item-publicado) {
  width: 100px;
}

.gap-3 {
  gap: 1rem;
}</style>
