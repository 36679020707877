<template>
    <div class="grid">
        <div class="col-12">
            <div class="grid">
                <div class="field col-12 md:col-6">
                    <label for="ean">Código de Barras</label>
                    <InputText
                        id="ean"
                        type="text"
                        v-model="ean"
                        :class="(fieldsError.some(v => v === 'ean'))?'w-full p-invalid':'w-full'"
                        maxlength="50"
                        @blur="carregarEAN($event)"
                        @keyup="carregarEAN($event)"
                    />
                </div>

                <div class="field col-12 md:col-6" v-if="bula">
                    <label>&nbsp;</label>
                    <Button @click="link(bula)" label="Ver Bula"></Button>
                </div>

                <div class="field col-12">
                    <label for="categoria">Categoria</label>
                    <Dropdown 
                        id="categoria"
                        v-model="categoria" 
                        editable 
                        :options="categorias_unificadas" 
                        optionLabel="nome" 
                        placeholder="Categoria" 
                        :class="(fieldsError.some(v => v === 'categoria'))?'w-full p-invalid':'w-full'"
                    />
                </div>

                <div class="field col-12">
                    <label for="nome">Nome</label>
                    <InputText
                        id="nome"
                        type="text"
                        v-model="nome"
                        :class="(fieldsError.some(v => v === 'nome'))?'w-full p-invalid':'w-full'"
                        maxlength="50"
                    />
                </div>
                <div class="field col-12">
                    <label for="descricao">Descrição</label>
                    <Textarea
                        id="descricao"
                        type="text"
                        v-model="descricao"
                        :class="(fieldsError.some(v => v === 'descricao'))?'w-full p-invalid':'w-full'"
                        :autoResize="true"
                        rows="3"
                    />
                </div>

                <div class="field col-12">
                    <label for="marca">Marca</label>
                    <InputText
                        id="marca"
                        type="text"
                        v-model="marca"
                        :class="(fieldsError.some(v => v === 'marca'))?'w-full p-invalid':'w-full'"
                        maxlength="50"
                    />
                </div>

                <div class="field col-12">
                    <label class="mb-3">Disponibilidade</label>
                    <div class="grid">
                        <div class="col-4 p-1" v-for="d of disponibilidades" :key="d.dia">
                            <Checkbox name="dia" :value="d.dia" v-model="disponibilidade" />
                            {{ d.nome }}
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <div class="field">
                        <label for="descricao">Valor</label>
                        <InputNumber
                            id="valor"
                            mode="currency"
                            currency="BRL"
                            locale="pt-BR"
                            v-model="valor"
                            :class="fieldsError.some((v) => v === 'valor')?'p-invalid':''"
                        />
                    </div>

                    <div class="mt-4">
                        <Button label="Gravar" class="p-button-success p-button-lg" icon="pi pi-check" @click="gravar"></Button>
                    </div>

                </div>
                <div class="field col-4">

                    <FileUpload 
                        name="imagem" 
                        mode="basic" 
                        :auto="true" 
                        :customUpload="true" 
                        class="upload_imagem"
                        :style="'background-image: url(' + foto + ')'"
                        :ref="(el) => this.upload = el"
                        @uploader="upload_imagem"
                     />

                </div>
            </div>
        </div>
        <div class="col-6" v-if="false">
            <div class="grid">
                <div class="field col-12">
                    
                    <ScrollPanel style="width: 100%; height: 410px; border-left: 1px solid #ccc; padding-left: 4%;">

                        <Accordion :multiple="true" class="w-full mb-3 mt-3">
                            <AccordionTab v-for="cat in personalizacoes" :key="cat.nome">
                            <template #header>
                                <i class="pi pi-sliders-v"></i>
                                <span class="ml-2">{{ cat.nome }}</span>
                                <span style="margin-left: auto; order: 2;">
                                    <Button icon="pi pi-plus" class="p-button-rounded" v-tooltip.top="'Nova Personalização'" @click="cadastro_personalizacao($event, cat)" />
                                    <Button icon="pi pi-pencil" class="ml-2 p-button-warning p-button-rounded" v-tooltip.top="'Alterar Categoria de Personalização'" @click="cadastro_categoria($event, cat)" />
                                    <Button icon="pi pi-trash" class="ml-2 p-button-danger p-button-rounded" v-tooltip.top="'Excluir Categoria de Personalização'" @click="excluir_categoria($event, cat)" />
                                </span>
                            </template>

                            <div class="w-full" v-if="typeof cat.itens != 'object' || Object.values(cat.itens).length == 0">
                                Nenhum ítem cadastrado para esta categoria de personalização.
                            </div>

                            <div class="grid item" v-for="item of cat.itens" :key="item.id">
                                <div class="col-12 md:col-8">
                                    {{ item.nome }}
                                </div>
                                <div class="col-12 md:col-4 text-right">
                                    {{ $utils.formatCurrency(item.valor) }}
                                </div>
                                <div class="col-12" v-if="item.descricao">
                                    <i>{{ item.descricao }}</i>
                                </div>
                                <div class="col-12 md:col-7">
                                    <span style="vertical-align: middle; margin-right: 0.4em;">Publicado: </span>
                                    <InputSwitch style="vertical-align: middle" v-model="item.publicado" />
                                </div>
                                <div class="col-12 md:col-5 text-right">
                                    <Button icon="pi pi-pencil" v-tooltip.top="'Editar Personalização'" class="ml-2 p-button-warning p-button-rounded" @click="cadastro_personalizacao($event, cat, item)" />
                                    <Button icon="pi pi-trash"  v-tooltip.top="'Excluir Personalização'" class="ml-2 p-button-danger p-button-rounded" @click="excluir_personalizacao($event, item)" />
                                </div>
                            </div>

                            </AccordionTab>
                        </Accordion>

                        <div class="text-right"><Button label="Nova Categoria de Personalizações" class="p-button-sm" icon="pi pi-plus" @click="cadastro_categoria($event, null)"></Button></div>

                    </ScrollPanel>
                </div>

            </div>
        </div>

    </div>

    <Dialog v-model:visible="categoria_edit_visible" style="width: 400px" :modal="true">
      <template #header>
        <div>
          <i class="pi pi-sliders-v text-2xl"></i> <span class="text-2xl font-semibold">Cadastro de Categoria de Personalizações</span>
        </div>
      </template>
      <CategoriaEdit :categoria="categoria_edit" @save="categoria_gravada" />
    </Dialog>

    <Dialog v-model:visible="personalizacao_edit_visible" style="width: 400px" :modal="true">
      <template #header>
        <div>
          <i class="pi pi-tag text-2xl"></i> <span class="text-2xl font-semibold">Cadastro de Personalizações</span>
        </div>
      </template>
      <PersonalizacaoEdit :categoria="personalizacao_edit_categoria" :personalizacao="personalizacao_edit" @save="personalizacao_gravada" />
    </Dialog>

    <ConfirmPopup></ConfirmPopup>

</template>

<script>

import CategoriaEdit from "./CategoriaPersonalizacaoEdit.vue";
import PersonalizacaoEdit from "./PersonalizacaoEdit.vue";

import firebase from 'firebase/app';
import 'firebase/storage';

import { v4 as uuid } from 'uuid';

export default {
    props: {
        produto: Object,
        categorias: Object
    },
    emits: [
        "save"
    ],
    data() {
        return {
            novo: true,
            upload: null,

            // 1.0 Validação
            fieldsError: [],

            // 2.0 Campos Formulário
            nome: "",
            descricao: "",
            foto: "https://firebasestorage.googleapis.com/v0/b/mega-logistica.appspot.com/o/vipdelivery%2Fimage-placeholder.png?alt=media&token=274da90b-efc8-428e-8287-6cf2026b5f98",
            valor: 0,
            disponibilidade: ["domingo", "segunda", "terca", "quarta", "quinta", "sexta", "sabado",],

            // 3.0 Chaves Estrangeiras
            disponibilidades: [
                {"dia":"domingo", "nome":"Domingo"},
                {"dia":"segunda", "nome":"Segunda-feira"},
                {"dia":"terca", "nome":"Terca-feira"},
                {"dia":"quarta", "nome":"Quarta-feira"},
                {"dia":"quinta", "nome":"Quinta-feira"},
                {"dia":"sexta", "nome":"Sexta-feira"},
                {"dia":"sabado", "nome":"Sábado"}
            ],
            
            personalizacoes: {},

            categoria_edit_visible: false,
            categoria_edit: null,

            personalizacao_edit_visible: false,
            personalizacao_edit_categoria: null,
            personalizacao_edit: null,

            ean: "",
            categoria: null,
            categorias_unificadas: [],
            bula: "",
            marca: ""

        }
    },
    watch: {
        $props: {
            handler() {

                if(typeof this.produto == "object" && this.produto != null) {
                    this.novo = false;

                    this.nome = this.produto.nome;
                    this.descricao = this.produto.descricao;
                    this.foto = this.produto.foto;
                    this.valor = this.produto.valor;
                    this.personalizacoes = this.produto.personalizacoes;
                    this.disponibilidade = Object.values(this.produto.disponibilidade);

                    this.ean = (typeof this.produto.ean == "string")?this.produto.ean:"";
                    this.categoria = this.categorias_unificadas.find((obj) => obj.id == this.produto.categoria);
                    this.bula = (typeof this.produto.bula == "string")?this.produto.bula:"";
                    this.marca = (typeof this.produto.marca == "string")?this.produto.marca:"";

                } else {

                    this.limparTela();

                }
                
            },
            deep: true,
            immediate: true,
        },
    }, 
    async mounted() {

        if(typeof this.categorias == "object" && this.categorias != null)
            this.categorias_unificadas = Object.values(this.categorias);
        else
            this.categorias_unificadas = [];

        const snapshot = await firebase.database().ref("ProdutosFarmaciaMatriz/Categorias").once("value");

        for(const c of Object.values(snapshot.val())) {

            if(typeof this.categorias_unificadas.find((obj) => obj.id == c.id) == "undefined") {
                this.categorias_unificadas.push({
                    id: c.id,
                    nome: c.nome
                });
            }

        }

    },
    methods: {

        link: (link) => {
            window.open(link, '_blank');
        },

        limparTela: function() {

            this.novo = true;

            this.nome = "";
            this.descricao = "";
            this.foto = "https://firebasestorage.googleapis.com/v0/b/mega-logistica.appspot.com/o/vipdelivery%2Fimage-placeholder.png?alt=media&token=274da90b-efc8-428e-8287-6cf2026b5f98";
            this.valor = 0;
            this.personalizacoes = {};
            this.disponibilidade = ["domingo", "segunda", "terca", "quarta", "quinta", "sexta", "sabado",];
            this.ean = "";
            this.categoria = null;
            this.bula = "";
            this.marca = "";

            this.fieldsError = [];

        },

        carregarEAN: async function(event) {

            if(typeof event.target.value == "string")
                event.target.value = event.target.value.trim();
            else
                event.target.value = "";
            
            if(typeof event.keyCode == "number" && event.keyCode != 13 || event.target.value == "") {
                return;
            }

            const snapshot = await firebase.database().ref("ProdutosFarmaciaMatriz/Produtos").orderByChild("ean").equalTo(event.target.value).once("value");

            if(!snapshot.exists()) {

                this.limparTela();

                this.$toast.add({
                    severity: "error",
                    summary: "Não Encontrado!",
                    detail: "O código de barras informado não foi encontrado em nosso banco de dados!",
                    life: 3000,
                });
                return;

            }

            const produto = Object.values(snapshot.val())[0];

            if(produto.nome == this.nome) {
                return;
            }

            this.nome = produto.nome;
            this.descricao = produto.descricao;
            if(produto.foto)
                this.foto = "https://firebasestorage.googleapis.com/v0/b/mega-logistica.appspot.com/o/Farmacia%2FFotos%2F" + produto.foto + "?alt=media";
            else
                this.foto = "https://firebasestorage.googleapis.com/v0/b/mega-logistica.appspot.com/o/vipdelivery%2Fimage-placeholder.png?alt=media&token=274da90b-efc8-428e-8287-6cf2026b5f98";
            this.valor = (produto.valor > 0)?produto.valor:((typeof produto.valor_alternativo == "number")?produto.valor_alternativo:0);
            this.categoria = this.categorias_unificadas.find((obj) => obj.id == produto.categoria.id);
            this.marca = produto.marca;
            if(produto.bula)
                this.bula = "https://firebasestorage.googleapis.com/v0/b/mega-logistica.appspot.com/o/Farmacia%2FFotos%2F" + produto.bula + "?alt=media";

            console.log(produto);

        },

        upload_imagem: function (event) {

            const self = this;

            if(event.files.length > 0) {

                const storageRef = firebase.storage().ref();

                storageRef.child('/vipdelivery/produtos/' + this.$root.user.id + '/' + uuid() + '.' + event.files[0].name.split('.').pop())
                .put(event.files[0]).then(function(snapshot) {
                    snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        self.foto = downloadURL;

                        self.upload.clear();

                    });
                });

            }

        },

        cadastro_categoria: function(event, categoria = null) {
            event.stopPropagation();

            this.categoria_edit = categoria;
            this.categoria_edit_visible = true;

        },

        categoria_gravada: function(event) {
            this.categoria_edit_visible = false;
            this.categoria_edit = null;

            if(typeof event.value.itens == "undefined")
                event.value.itens = {};

            if(typeof this.personalizacoes == "undefined")
                this.personalizacoes = {};

            if(typeof event.value.id == "undefined") { 
                
                if(Object.keys(this.personalizacoes).length == 0)
                    event.value.id = '-A0';
                else
                    event.value.id = '-A' + (parseInt(Object.keys(this.personalizacoes).pop().replace("-A",""))+1).toString();

            }

            this.personalizacoes[event.value.id] = event.value;

        },

        excluir_categoria: function(event, categoria) {
            event.stopPropagation();

            const self = this;

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Tem certeza que deseja excluir essa categoria e seus ítens?',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {

                    delete self.personalizacoes[categoria.id];

                    self.$toast.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Categoria e ítens excluídos com sucesso!",
                        life: 3000,
                    });

                }
            });

        },

        cadastro_personalizacao: function(event, categoria, personalizacao = null) {
            event.stopPropagation();
            this.personalizacao_edit = personalizacao;
            this.personalizacao_edit_categoria = categoria.id;
            this.personalizacao_edit_visible = true;
        },

        personalizacao_gravada: function(event) {
            this.personalizacao_edit_visible = false;
            this.personalizacao_edit = null;

            if(typeof event.value.id == "undefined") {

                if(Object.keys(this.personalizacoes[event.value.categoria].itens).length == 0)
                    event.value.id = "-A0";
                else
                    event.value.id = "-A" + (parseInt(Object.keys(this.personalizacoes[event.value.categoria].itens).pop().replace("-A",""))+1).toString();

            }

            this.personalizacoes[event.value.categoria].itens[event.value.id] = event.value;
        
        },

        excluir_personalizacao: function(event, personalizacao) {
            event.stopPropagation();

            const self = this;

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Tem certeza que deseja excluir esse item?',
                icon: 'pi pi-exclamation-triangle',
                acceptClass: 'p-button-danger',
                accept: () => {

                    delete self.personalizacoes[personalizacao.categoria].itens[personalizacao.id];

                    self.$toast.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: "Item excluído com sucesso!",
                        life: 3000,
                    });

                }
            });

        },

        validateForm: function() {

            this.fieldsError = [];

            if (this.nome == "") {
                this.fieldsError.push("nome");
            }

            if (this.valor <= 0) {
                this.fieldsError.push("valor");
            }

            if (this.disponibilidade.length == 0) {
                this.fieldsError.push("disponibilidade");
            }

            if(typeof this.categoria == "string" && this.categoria.trim() == "") {
                this.fieldsError.push("categoria");
            } else if(typeof this.categoria != "object" || this.categoria == null) {
                this.fieldsError.push("categoria");
            }

            if (this.fieldsError.length) {
                this.$toast.add({
                    severity: "error",
                    summary: "ERRO!",
                    detail: "Preencha corretamente todas as informações!",
                    life: 5000,
                });

                return false;
            }

            return true;
        },

        gravar: function() {
            if(this.validateForm()) {
                
                let produto;

                if(this.novo) {
                    produto = {};
                } else {
                    produto = this.$utils.getStdObject(this.produto);
                }

                if(typeof this.categoria == "string") {
                    produto.categoria = uuid();
                    produto.categoriaNome = this.categoria;
                } else {
                    produto.categoria = this.categoria.id;
                    produto.categoriaNome = this.categoria.nome;
                }

                produto.cadastrarCategoria = true;

                produto.nome = this.nome;
                produto.descricao = this.descricao;
                produto.foto = this.foto;
                produto.valor = this.valor;

                produto.ean = (typeof produto.ean == "string")?produto.ean:"";
                produto.bula = (typeof produto.bula == "string")?produto.bula:"";
                produto.marca = (typeof produto.marca == "string")?produto.marca:"";

                if(typeof this.personalizacoes == "undefined")
                    this.personalizacoes = {};

                produto.personalizacoes = this.personalizacoes;
                produto.disponibilidade = this.disponibilidade;

                this.$emit("save", {value: produto});

            }
        },

    },
    components: {
        CategoriaEdit,
        PersonalizacaoEdit
    }
}
</script>
<style scoped>

    :deep(.upload_imagem) {
        width: 117px;
        height: 117px;
        border: none;
        background-size: 100% !important;
    }
    
    :deep(.upload_imagem .p-button-label) {
        display: none;
    }
    
    :deep(.upload_imagem .p-button-icon) {
        color: black;
        font-size: 2rem;
        margin-left: 4.8rem;
        margin-right: 0;
        margin-bottom: 5rem;
    }

    :deep(.upload_imagem .p-button-icon:before) {
        content: "\e944";
    }

    :deep(.field) {
        margin-bottom: 0
    }

    :deep(.p-accordion .p-accordion-header .p-accordion-header-link) {
        padding: 0.3rem 1rem;
    }

    :deep(.item) {
        border-bottom: 1px solid #ccc;
        padding-bottom: 0.4rem;
        margin-bottom: 0.6rem;
    }

    :deep(.p-accordion-content .item:last-child) {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
    }


</style>